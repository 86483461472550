<script setup>
import { inject, reactive, ref, watch } from 'vue'
import { notify } from '@kyvg/vue3-notification'
import { deepCopy } from '@/utils/common'
import { REQUISITES_TYPES } from '@/models/P2P/RequisitesModel'

const emit = defineEmits(['save', 'delete'])

const saved = ref(false)
const requisitesByType = inject('requisitesByType')

const fields = reactive({
  company_name: '',
  address: '',
  account_number: '',
  bank: '',
  bank_address: '',
  swift_code: '',
  bank_code: '',
  account_holder_name: '',
  bank_country: '',
  account_type: '',
  routing_number: ''
})
if (requisitesByType.value[REQUISITES_TYPES.FOREIGN_ACCOUNT].length) {
  const item = requisitesByType.value[REQUISITES_TYPES.FOREIGN_ACCOUNT][0]
  Object.keys(item).forEach(prop => {
    fields[prop] = item[prop]
  })
  saved.value = true
}

const requiredFields = reactive({
  company_name: '',
  address: '',
  account_number: '',
  account_holder_name: '',
  bank: '',
  bank_country: '',
  bank_address: '',
  account_type: '',
  swift_code: '',
  routing_number: '',
  bank_code: ''
})

const errors = reactive({
  company_name: null,
  address: null,
  account_number: null,
  account_holder_name: null,
  bank: null,
  bank_country: null,
  bank_address: null,
  account_type: null,
  swift_code: null,
  routing_number: null,
  bank_code: null
})

const validate = () => {
  if (!validateRequired()) return false
  return true
}

const validateRequired = () => {
  Object.keys(requiredFields).forEach(prop => {
    if (fields[prop] === '') errors[prop] = true
  })
  if (Object.values(errors).some(Boolean)) {
    notify({
      title: window.$t('error_title'),
      text: $t('fields_is_required'),
      type: 'error'
    })
    return false
  }
  return true
}

const save = () => {
  if (saved.value || !validate()) return
  emit('save', deepCopy(fields))
  saved.value = true
}

const deleteData = () => {
  if (!saved.value) return
  Object.keys(fields).forEach(field => { fields[field] = '' })
  emit('delete')
}

watch(fields, (to) => {
  if (saved.value) saved.value = false
})

const resetErrors = () => { Object.keys(errors).forEach(p => { errors[p] = null })}
</script>

<template lang="pug">
.ce-foreign-account
  .ce-foreign-account_fields
    .ce-foreign-account_fields-company.ce-shadow-block
      .label Информация о компании
      .fields
        ce-text-field(
          v-model="fields.company_name"
          :label="'Название'"
          :error="errors.company_name"
          :required="true"
          @update:model-value="errors.company_name = ''"
        )
        ce-text-field(
          v-model="fields.address"
          :label="'Адрес'"
          :error="errors.address"
          :required="true"
          @update:model-value="errors.address = ''"
        )
    .ce-foreign-account_fields-bank.ce-shadow-block
      .label Информация о банке
      .fields
        ce-text-field(
          v-model="fields.account_number"
          :label="'Номер счёта'"
          :error="errors.account_number"
          :required="true"
          @update:model-value="errors.account_number = ''"
        )
        ce-text-field(
          v-model="fields.account_holder_name"
          :label="'Имя держателя счёта'"
          :error="errors.account_holder_name"
          :required="true"
          @update:model-value="errors.account_holder_name = ''"
        )
        ce-text-field(
          v-model="fields.bank"
          :label="'Название банка'"
          :error="errors.bank"
          :required="true"
          @update:model-value="errors.bank = ''"
        )
        ce-text-field(
          v-model="fields.bank_country"
          :label="'Страна банка'"
          :error="errors.bank_country"
          :required="true"
          @update:model-value="errors.bank_country = ''"
        )
        ce-text-field(
          v-model="fields.bank_address"
          :label="'Адрес банка'"
          :error="errors.bank_address"
          :required="true"
          @update:model-value="errors.bank_address = ''"
        )
        ce-text-field(
          v-model="fields.account_type"
          :label="'Тип счета'"
          :error="errors.account_type"
          :required="true"
          @update:model-value="errors.account_type = ''"
        )
        ce-text-field(
          v-model="fields.swift_code"
          :label="'SWIFT/БИК'"
          :error="errors.swift_code"
          :required="true"
          @update:model-value="errors.swift_code = ''"
        )
        ce-text-field(
          v-model="fields.routing_number"
          :label="'Номер маршрута'"
          :error="errors.routing_number"
          :required="true"
          @update:model-value="errors.routing_number = ''"
        )
        ce-text-field(
          v-model="fields.bank_code"
          :label="'Код банка'"
          :error="errors.bank_code"
          :required="true"
          @update:model-value="errors.bank_code = ''"
        )
  .ce-foreign-account_actions
    button.ce-button.ce-button--yellow(
      :class="{ saved }"
      @click="save"
    ) Сохранить
    button.ce-button.ce-button--brand-red2(
      :class="{ disabled: !saved }"
      @click="deleteData"
    ) Удалить
</template>

<style scoped lang="scss">
.ce-foreign-account {
  max-height: 70vh;
  &_fields {
    @include forDesktop_v2 {
      display: grid;
      grid-gap: 1rem;
      min-width: 800px;
    }
    .label {
      font-size: 1rem;
      font-family: Geometria, sans-serif;
      font-weight: 500;
      margin-bottom: 1.25rem;
    }
    .fields {
      display: grid;
      grid-gap: 0.5rem;
      @include forDesktop_v2 {
      }
    }
    &-bank {
      .fields {
        @include forDesktop_v2 {
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
  &_actions {
    margin: 1rem 0 0 0;
    display: grid;
    grid-gap: 0.5rem;
    @include forDesktop_v2 {
      display: flex;
      gap: 0.5rem;
      justify-content: flex-end;
    }
    button {
      @include forDesktop_v2 {
        width: 30%;
      }
      &.saved {
        background: $Brand_Light_Grey!important;
        border: 1px solid $Brand_Light_Grey!important;
        &:hover {

        }
      }
    }
  }
}
</style>
